<template>
  <v-container fluid>
    <v-row dense>
      <v-row class="mb-2">
        <v-col cols="3">
          <v-text-field
            outlined
            label="Select Item"
            single-line
            v-model="record.Code"
            :item-text="record.Code"
            :item-value="record.Code"
            :append-icon="'mdi-airballoon'"
            @click:append="getItemsMaster()"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            dense
            type="text"
            label="Item Name"
            small
            v-model="record.Name"
            :item-text="record.Name"
            :item-value="record.Name"
            :append-icon="'mdi-airballoon'"
            @click:append="getItemsMaster()"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-autocomplete
            small
            outlined
            dense
            v-model="record.ToWH"
            item-text="WhsName"
            item-value="WhsCode"
            label="WareHouse"
            :items="WareHouses"
          ></v-autocomplete>
        </v-col>
        <v-col col="2">
          <v-autocomplete
            small
            outlined
            dense
            v-model="record.Type"
            :items="typesArray"
            label="Type"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            label="TreeType"
            outlined
            dense
            small
            v-model="record.TreeType"
            :items="treeTypeArray"
          ></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-autocomplete
            small
            outlined
            dense
            v-model="record.PriceList"
            item-text="ListName"
            item-value="ListNum"
            label="PriceList"
            :items="PriceLists"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-menu
            ref="menu"
            v-model="record.validFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="record.ValidFrom"
                label="Valid From"
                outlined
                dense
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="record.ValidFrom" no-title scrollable></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-menu
            ref="menu"
            v-model="record.validTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="record.ValidTo"
                label="Valid To"
                outlined
                dense
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="record.ValidTo" no-title scrollable></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-text-field
            outlined
            dense
            type="number"
            label="Quantity"
            v-model="record.Quantity"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-tabs background-color="accent" color="white">
        <v-tab>Promo Item</v-tab>
        <v-tab>Business Partners</v-tab>

        <v-tab-item>
          <v-btn color="success" @click="addpti1" small class="my-2"> Add Item </v-btn>
          <v-card v-for="(pti1, index) in pti1s" :key="index" class="pa-2 mb-2">
            <v-card-text>
              <v-row dense>
                <v-col cols="2">
                  <v-text-field
                    outlined
                    type="text"
                    label="Item Code"
                    v-model="pti1.Code"
                    :item-text="pti1.Code"
                    :item-value="pti1.Code"
                    :append-icon="'mdi-airballoon'"
                    @click:append="getSubItemsMaster(index)"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Item Quantity"
                    v-model="pti1.Quantity"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-autocomplete
                    small
                    outlined
                    dense
                    v-model="pti1.Warehouse"
                    item-text="WhsName"
                    item-value="WhsCode"
                    label="Item Warehouse"
                    :items="WareHouses"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Item Price"
                    v-model="pti1.Price"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    small
                    label="Item Currency"
                    v-model="pti1.Currency"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-autocomplete
                    small
                    outlined
                    dense
                    v-model="pti1.PriceList"
                    item-text="ListName"
                    item-value="ListNum"
                    label="PriceList"
                    :items="PriceLists"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Discount Price"
                    v-model="pti1.OrigPrice"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <!-- <v-text-field
                    outlined
                    dense
                    type="text"
                    small
                    label="Uom"
                    v-model="pti1.Uom"
                  ></v-text-field> -->
                  <v-select
                    small
                    outlined
                    dense
                    v-model="pti1.Uom"
                    :items="ugpEntry.UomName"
                    :value="ugpEntry.UomName"
                    item-text="ugpEntry.UomName"
                    label="Uom"
                  ></v-select>
                </v-col>
                <!-- <v-col col="2">
                  <v-autocomplete
                    small
                    outlined
                    dense
                    v-model="pti1.Type"
                    :items="typesArray"
                    label="Type"
                  ></v-autocomplete>
                </v-col>                
                <v-col col="2">
                  <v-autocomplete
                    small
                    outlined
                    dense
                    v-model="pti1.TreeType"
                    :items="treeTypeArray"
                    label="TreeType"
                  ></v-autocomplete>
                </v-col> -->

                <v-col cols="2">
                  <v-checkbox
                    v-model="pti1.ForSale"
                    label="For Sale"
                    :true-value="1"
                    :false-value="0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="2">
                  <v-checkbox
                    v-model="pti1.Status"
                    label="Active"
                    :true-value="1"
                    :false-value="0"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="6" md="1">
                  <v-btn small color="error" @click="removepti1(index)"> Remove </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-row class="pt-4 pb-0">
            <v-col cols="12">
              <v-autocomplete
                small
                outlined
                dense
                v-model="business_partners[0].CardCode"
                item-text="CardName"
                item-value="CardCode"
                label="Business Partners"
                :items="BusinessPartners"
                chips
                multiple
                clearable
                deletable-chips
                class="mb-2"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>

      <hr class="my-4" />

      <v-col cols="12">
        <v-btn color="accent" @click="sendData">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>

    <!-- one item selection modal -->
    <v-dialog v-model="itemDialog" width="950">
      <v-toolbar dark color="primary">
        <v-toolbar-title>List of Items</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="itemDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchItem"
                    append-icon="mdi-magnify"
                    label="Select using Item Code or Item Name"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  dense
                  @item-selected="clickedItem"
                  :items-per-page="itemsPerPage"
                  v-model="selectedItems"
                  :headers="itemMasterHeaders"
                  :items="itemMasterData"
                  item-key="id"
                  show-select
                  :options.sync="options"
                  :server-items-length="totalItems"
                  class="elevation-1"
                  :server-search="true"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of item modal -->

    <!-- subitem selection modal -->
    <v-dialog v-model="subitemDialog" width="950">
      <v-toolbar dark color="primary">
        <v-toolbar-title>List of SubItems</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="subitemDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchSubItem"
                    append-icon="mdi-magnify"
                    label="Select using SubItem Code or SubItem Name"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  dense
                  @item-selected="clickedSubItem"
                  :items-per-page="itemsPerPage"
                  v-model="selectedSubItems"
                  :headers="subitemMasterHeaders"
                  :items="subitemMasterData"
                  item-key="id"
                  show-select
                  :options.sync="options"
                  :server-items-length="totalSubItems"
                  class="elevation-1"
                  :server-search="true"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- subitem selection modal -->
  </v-container>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    itemDialog: false,
    subitemDialog: false,
    itemsPerPage: 100,
    record: {
      Type: "A",
    },
    item: {},
    subitem: {},
    typesArray: [
      {
        text: "All Business Partners",
        value: "A",
      },
      {
        text: "Specific Business Partners",
        value: "S",
      },
    ],
    treeTypeArray: [
      {
        text: "Assembly",
        value: "A",
      },
      {
        text: "Production",
        value: "P",
      },
      {
        text: "Template",
        value: "T",
      },
      {
        text: "Sales",
        value: "S",
      },
    ],
    currencyArray: [
      {
        text: "KES",
        value: "KES",
      },
      {
        text: "USD",
        value: "USD",
      },
      {
        text: "EUR",
        value: "EUR",
      },
      {
        text: "GBP",
        value: "GBP",
      },
    ],
    loader: false,
    Attibutes: [],
    Attr: [],
    WareHouses: [],
    //pti1s: [{  }],
    PriceLists: [],
    BusinessPartners: [],
    itemMasterData: [],
    Uoms: [],
    subitemMasterData: [],
    searchItem: null,
    searchSubItem: null,
    selectedItems: null,
    selectedSubItems: null,
    menu: null,
    selectedItem: {},
    selectedSubItem: {},
    currentrow: null,
    itemMasterHeaders: [
      { text: "Item Code", value: "ItemCode" },
      { text: "Item Description", value: "ItemName" },
      { text: "In Stock", value: "OnHand" },
    ],
    subitemMasterHeaders: [
      { text: "SubItem Code", value: "ItemCode" },
      { text: "SubItem Description", value: "ItemName" },
      { text: "In Stock", value: "OnHand" },
    ],
    pti1s: [
      {
        Code: "",
        Quantity: "",
        Warehouse: "",
        Price: "",
        Currency: "",
        PriceList: "",
        OrigPrice: "",
        OrigCurr: "",
        Uom: "",
        Type: "",
        TreeType: "",
        ForSale: 1,
        Status: 1,
      },
    ],
    // business_partners: {
    //   CardCode: ""
    // },
    business_partners: [
      {
        CardCode: "",
      },
    ],
    ugpEntry: {},
    options: {
      rowsPerPageOptions: [10, 20, 30],
      itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 10,
      page: 1,
    },
    totalItems: 0,
    totalSubItems: 0,
    dialog: false,
  }),
  watch: {
    options: {
      handler() {
        this.getItemsMaster();
        this.getSubItemsMaster();
      },
      deep: true,
    },
    searchItem() {
      this.options.page = 1;
      if (this.searchItem.length >= 2) {
        this.getItemsMaster();
      }
      if (this.searchItem.length == 0) {
        this.getItemsMaster();
      }
    },
    searchSubItem() {
      this.options.page = 1;
      if (this.searchSubItem.length >= 2) {
        this.getSubItemsMaster();
      }
      if (this.searchSubItem.length == 0) {
        this.getSubItemsMaster();
      }
    },
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
      console.log(this.record);

      if (val && val.oitm) {
        this.ugpEntry = this.record.ugp[0].uomentry;
        //console.log(JSON.stringify(this.ugpEntry));
      }

      if (val && val.item_lines) {
        this.subItems = val.item_lines;
      }
      if (val && val.pti1s) {
        this.pti1s = val.pti1s;
      }
      if (val && val.business_partners) {
        this.business_partners[0].CardCode = val.business_partners;
        //console.log(this.business_partners);
      }
    },
    clickedItem($event) {
      this.selectedItem = $event.item;
      this.record.Code = this.selectedItem.ItemCode;
      this.record.Name = this.selectedItem.ItemName;
      this.itemDialog = false;
    },
    clickedSubItem($event) {
      //console.log($event);
      this.selectedSubItem = $event.item;
      this.pti1s[this.currentrow].Code = this.selectedSubItem.ItemCode;
      this.pti1s[this.currentrow].Name = this.selectedSubItem.ItemName;
      this.subitemDialog = false;
    },
    addpti1() {
      this.pti1s.push({
        Code: "",
        Quantity: "",
        Warehouse: "",
        Price: "",
        Currency: "",
        PriceList: "",
        OrigPrice: "",
        OrigCurr: "",
        Uom: "",
        Type: "",
        TreeType: "",
        ForSale: "",
      });
    },
    removepti1(index) {
      this.pti1s.splice(index, 1);
    },
    sendData() {
      this.loader = true;
      this.record.pti1s = this.pti1s;
      this.record.business_partners = this.business_partners;
      this.$emit("data", this.record, this.pti1s, this.business_partners);
      //console.log(this.record);
    },
    getItemsMaster() {
      this.itemDialog = true;
      const self = this;
      this.loading = true;
      let url = `/item_masterdata?page=${this.options.page}&per_page=${this.options.itemsPerPage}`;

      if (this.searchItem && this.searchItem != undefined) {
        url += `&search=${this.searchItem}`;
      }

      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.itemMasterData = res.ResponseData.data;
          self.options.page = res.ResponseData.current_page;
          self.options.itemsPerPage = res.ResponseData.per_page;
          self.totalItems = res.ResponseData.total;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getSubItemsMaster(index) {
      this.currentrow = index;
      this.subitemDialog = true;
      const self = this;
      this.loading = true;
      let url = `/item_masterdata?page=${this.options.page}&per_page=${this.options.itemsPerPage}`;

      if (this.searchSubItem && this.searchSubItem != undefined) {
        url += `&search=${this.searchSubItem}`;
      }

      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.subitemMasterData = res.ResponseData.data;
          self.options.page = res.ResponseData.current_page;
          self.options.itemsPerPage = res.ResponseData.per_page;
          self.totalSubItems = res.ResponseData.total;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getPriceList() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/price_lists`)
        .then((res) => {
          self.PriceLists = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getUoms() {
      const self = this;
      this.loading = true;
      if (this.ugpEntry) {
        let url = `/item-uom/${this.ugpEntry}`;
        console.log(url);
        this.$store
          .dispatch("get", url)
          .then((res) => {
            self.Uoms = res.ResponseData.data;

            //console.log(this.Uoms);
            self.loading = false;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
      }
    },
    getBpartners() {
      this.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          let Partners = {
            CardCode: "-1",
            CardName: "ALL",
          };
          this.BusinessPartners = res.ResponseData;
          this.BusinessPartners.push(Partners);
          this.BusinessPartners.sort((a, b) => a.CardCode - b.CardCode);
          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getWareHouses() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/warehouse`)
        .then((res) => {
          self.WareHouses = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getPriceList();
    this.getBpartners();
    this.getWareHouses();
    this.getUoms();
  },
};
</script>
<style>
.hidden-element {
  display: none;
}
</style>
